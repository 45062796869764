import React, { useEffect, useState } from 'react';
import { Link as NavLink } from 'react-router-dom';

export default function FeaturedCategory() {
    const [data, setData] = useState([])
    useEffect(() => {
        fetch('https://pesabee.sdsstaging.co.uk/API/api/featured_category', { method: 'GET', headers: { "Content-Type": "application/json" } })
            .then(res => res.json())
            .then((result) => {
                setData(result.data)
            })
            .catch(error => console.log(error))
    }, [])
    return (
        <>
            {/* <section className="section-category">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="deals-list">
                                <ul className="categories-link">
                                    {
                                        data && data.map((dataclear) => (
                                            <li>
                                                <NavLink to={"/Product-lists/" + dataclear?.category_name}>
                                                    <div className="f-accesoories"><img src={dataclear?.category_icon} alt="" /></div>
                                                    <div className="daily-deals daily_para"><p>{dataclear?.category_name} </p></div>
                                                </NavLink>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="section-featured">
                <div className="container">
                    <div className="featured-categories">
                        <ul className="categories-list">
                            {
                                data && data.map((dataclear) => (
                                    <li>
                                        <div className="f-clist1">
                                            <img src={dataclear?.category_icon} /><p>{dataclear?.category_name}</p>
                                        </div>
                                    </li>
                                ))}
                            {/* <li>
                                <div className="f-clist2">
                                    <img src="image/icon2.png" /><p>Phones &<br /> Wearbles</p>
                                </div></li>
                            <li>
                                <div className="f-clist3">
                                    <img src="image/icon3.png" /><p>Clothes &<br /> Accessories</p>
                                </div>
                            </li>
                            <li><div className="f-clist4"><img src="image/icon4.png" /><p>Sports & <br />Outdoor</p></div></li>
                            <li><div className="f-clist5"><img src="image/icon5.png" /><p>Health & <br />Beauty</p></div></li> */}
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}