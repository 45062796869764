import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link as NavLink } from 'react-router-dom';

export default function Carousels() {
const [data, setData] = useState([])
useEffect(() => {
    fetch('https://pesabee.sdsstaging.co.uk/API/api/product_list', { method: 'GET', headers: { "Content-Type": "application/json" } })
        .then(res => res.json())
        .then((result) => {
            console.log(result)

            setData(result.data)
            // for (var newdata of result.data) {
            //     data.push(newdata)
            //     console.log(data)
            // }
        })


        .catch(error => console.log(error))
}, [])


    // var settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 5,
    //     slidesToScroll: 1,
    // };
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
        <Slider {...settings}>
            {
                data && data.map((dataclear) => (
                    <NavLink to={"/Product-detail/" + dataclear.id} >
                        <div className="item">
                            <div className="one-slide white">
                                <div className="testimonial">
                                    <div className="brand"><img src={dataclear.Image_URL} alt="product image" /></div>
                                    <div className="testimonial-specs">
                                        <p className="price-phone">{ dataclear.product_name }<span className="fa fa-heart-o position-relative"> </span></p>
                                        <p className="price-specs">from <span className="amount-p"> { dataclear.mrp } </span><span className="amount-cut">£240.00 </span></p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </NavLink>
                ))
            }
        </Slider>



    )

}