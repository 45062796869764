import React from 'react';
import { Route, BrowserRouter as Router, Switch, Link as NavLink } from 'react-router-dom';

export default function Footer(){
    
    return(
        <>
         <section className="footer-area overlay" >
        <div className="footer-widget-area">
            <div className="container">
                <div className="row">
               
                    <div className="col-lg-2 col-md-6">
                        <div className="single-footer-widget">
                            <h2>PesaBee</h2>
                            <div className="widget-body">
                                <ul className="social-list p-l-25">
                                <li><NavLink to='/About'>About Us</NavLink></li>
                                <li><NavLink to='/Contact'>Contact Us</NavLink></li>
                                <li><NavLink to='/Blog'>Blogs</NavLink></li>
                                <li><NavLink to='/Newfeature'>New Features</NavLink></li>
                                <li><NavLink to='/categories'>Categories A to Z</NavLink></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                       <div className="col-lg-3 col-md-6">
                        <div className="single-footer-widget">
                            <h2>Learn More</h2>
                            <div className="widget-body">
                                <ul className="social-list p-l-25">
                                <li><NavLink to='/'>Get Started</NavLink></li>
                                <li><NavLink to='/'>Safe Shopping</NavLink></li>
                                <li><NavLink to='/Membership'>Membership</NavLink></li>
                                <li><NavLink to='/Faq'>Frequently Asked Questions</NavLink></li>
                                <li><NavLink to='/Privacy'>Policies & Privacy</NavLink></li>
                                <li><NavLink to='/Terms'>Terms & Condition</NavLink></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                       <div className="col-lg-3 col-md-6">
                        <div className="single-footer-widget">
                            <h2>Shops & Advertisers</h2>
                            <div className="widget-body">
                                <ul className="social-list p-l-25">
                                <li><NavLink to='/Whypesabee'>Why Pesabee?</NavLink></li>
                                <li><NavLink to='/'>Register Your Shop</NavLink></li>
                                <li><NavLink to='/'>Merchant Dashboard</NavLink></li>
                                <li><NavLink to='/Deals'>Advertising</NavLink></li>
                                <li><NavLink to='/'>Widgets</NavLink></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                  <div className="col-lg-4 col-md-6">
                        <div className="single-footer-widget">
                            <h2>Get the mobile app</h2>
                            <div className="widget-body">
                                <div className="appli-gmail">
                                <NavLink to='/' className="footer-logo">
                                <img src={window.location.origin + '/image/playstore.png'} alt="" />
                                  </NavLink>
                                  <NavLink to='/' className="apple-link">
                                    <img src={window.location.origin + '/image/apple.png'} alt="" />
                                </NavLink>
                               </div> 
                               <h3 className="subscribe">Subscribe our Newsletter</h3>
                               <div className="text-search" id="text-search">
                                <input type="text" name="" className="form-control" placeholder="Email address" />
                                <button className="btn job-search">Subscribe</button>
                                </div>
                                
                            </div>
                            <div className=" follow-us">
                            <div className="row">
                            <div className="col-sm-12">
                            <div className="social-icons">
                                <span>Follow Us:</span>
                                <ul className="social-comobs">
                                    <li >
                                        <a href="https://www.facebook.com/" title="Facebook" target="_blank" className="fb"><i className="fa fa-facebook" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li >
                                        <a href="https://twitter.com/" title="Twitter" target="_blank" className="tweets"><i className="fa fa-twitter" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li >
                                        <a href="https://www.instagram.com/" title="Pinterest" target="_blank" className="instag"><i className="fa fa-instagram" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li >
                                        <a href="https://in.linkedin.com/" title="Youtube" target="_blank" className="link-in"><i className="fa fa-linkedin" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    
                                </ul>
                            </div>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
        
            </div>
        </div>
   </section>
    <div className="prperty-lane">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 text-left">
                    <p className="all-rights">&copy; 2021<span id="newyear"></span><NavLink to='/' ><span className="website-n">PesaBee.</span></NavLink> All Rights Reserved</p>
                </div>
                <div className="col-lg-6 text-right">
                    <p className="all-rights">Website by : <NavLink to='http://sdssoftwares.co.uk/'  target="_blank" className="all-rights"><span>sdssoftwares.co.uk</span></NavLink></p>
                </div>
            </div>
        </div>
    </div>
        </>
    )
}