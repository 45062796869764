import React from 'react';
import Home from '../pages/Home';
import About from '../pages/About';
import Shoppingguides from '../pages/Shopping-guides';
import Blog from '../pages/Blog';
import Contact from '../pages/Contact';
import Membership from '../pages/Membership';
import Terms from '../pages/Terms';
import Privacy from '../pages/Privacy';
import Categories from '../pages/Categories';
import ProductList from '../pages/Product-list';
import Store from '../pages/Store';
import Faq from '../pages/Faq';
import Deals from '../pages/Deals';
import Trends from '../pages/Trends';
import Expertreview from '../pages/Expert-review';
import Pricedrops from '../pages/Price-drops';
import ProductDetail from '../pages/Product-detail';
import Storeproduct from '../pages/Store-product';
import WhyPesabee from '../pages/Whypesabee';
import NewFeature from '../pages/Newfeature';
import ProductLists from '../pages/product-lists';
import FeaturedCategory from '../includes/Featured-category';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';


export default function Routing() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/Categories" component={Categories} />
                    <Route exact path="/Product-list" component={ProductList} />
                    <Route exact path="/Product-detail/:slug_id" component={ProductDetail} />
                    <Route exact path="/About" component={About} />
                    <Route exact path="/Store" component={Store} />
                    <Route exact path="/Faq" component={Faq} />
                    <Route exact path="/Deals" component={Deals} />
                    <Route exact path="/product-lists/:category" component={ProductLists} />
                    <Route exact path="/Store-product/:store" component={Storeproduct} />
                    <Route exact path="/Price-drops" component={Pricedrops} />
                    <Route exact path="/Trends" component={Trends} />
                    <Route exact path="/Featured-category" component={FeaturedCategory} />
                    <Route exact path="/Newfeature" component={NewFeature} />
                    <Route exact path="/Whypesabee" component={WhyPesabee} />
                    <Route exact path="/Expert-review" component={Expertreview} />
                    <Route exact path="/Shopping-guides" component={Shoppingguides} />
                    <Route exact path="/Blog" component={Blog} />
                    <Route exact path="/Contact" component={Contact} />
                    <Route exact path="/Membership" component={Membership} />
                    <Route exact path="/Privacy" component={Privacy} />
                    <Route exact path="/Terms" component={Terms} />
                </Switch>
            </div>
        </Router>
    )
}