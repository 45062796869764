import React, { useEffect, useState } from 'react';
import Header from '../includes/Header';
import Footer from '../includes/Footer';

export default function Expertreview() {
    const url = "https://pesabee.sdsstaging.co.uk/API/api/save_expert_review"
    const [data, setData] = useState({
        name:"",
        email_id:"",
        stars:"5",
        review:""
    })
    console.log(data)
    function submit(e) {
        e.preventDefault();
        fetch(url, {
             method: 'POST',
             name:data.name,
             email_id:data.email_id,
             stars:data.star,
             review:data.review,
               })
               .then(res=>{console.log(res.data)})
               
    }
    function handle(e) {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
        console.log(newdata)
    }
    
    
    return (
        <>
        <Header />
        <section  className="contact-us inner-banner">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title  text-center">
                        <h2>Expert Review</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div className=" section-overlay">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="contact-form">
                        <form onSubmit={(e) => submit(e)}>
                            <div className="form-group">
                                <input onChange={(e) => handle(e)} id="name" value={data.name} type="text" placeholder="Full Name" className="form-control" />
                            </div>
                            <div className="form-group">
                                <input onChange={(e) => handle(e)} id="email_id" value={data.email_id} type="email" placeholder="Email Address" className="form-control" />
                            </div>
                            <div className="form-group">
                                <textarea onChange={(e) => handle(e)} id="review" value={data.review} name="review" cols="30" rows="6" placeholder="Review" className="form-control"></textarea>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="submit-form">Submit Message</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-lg-1"></div>
               
            </div>
        </div>
    </div>

   
        <Footer />
        </>
    )
}