import React, { useCallback, useEffect, useState } from 'react';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import Maincategory from '../includes/Maincategory';
import { Link as NavLink } from 'react-router-dom';

export default function Categories() {
    const [data, setData] = useState([])
    useEffect(() => {
        fetch('https://pesabee.sdsstaging.co.uk/API/api/category_with_subcategory', { method: 'GET', headers: { "Content-Type": "application/json" } })
            .then(res => res.json())
            .then((result) => {
                setData(result.data)
            })
            .catch(error => console.log(error))
    }, [])
    return (
        <>
            <Header />
            <Maincategory />

            <section className="section-category">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="product-list">
                                <ul className="product-dtl">
                                {
                                        data && data.map((dataclear) => (
                                            <li className="col-lg-12">
                                            <h3 className="text-left"><NavLink to={"/Product-lists/" + dataclear?.category_name}>{dataclear?.category_name}</NavLink></h3>
                                            <ul className="col-lg-12 product-sub-list">
                                            {dataclear.sub_category && dataclear.sub_category.map((subcategorylist) => (
                                    <li className="col-lg-4"><NavLink to={"/Product-lists/" + subcategorylist.sub_category_name}>{subcategorylist.sub_category_name}</NavLink></li>
                                    
                                  ))}
                                             </ul>
                                        </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Footer />
        </>
    )
}