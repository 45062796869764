import React from 'react';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import Maincategory from '../includes/Maincategory';
import FeaturedCategory from '../includes/Featured-category';
import Carousels from '../includes/Carousels';
import { Link as NavLink } from 'react-router-dom';

export default function Home() {
    return (
        <>
            <Header />
            
            <section className="slider-area">
                <div className="single-slide-item overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6">
                                <p className="choose-market">Choose your favorite market </p>
                                <h1 className="banner-title"><span >Compare</span> Best Prices</h1>
                                <p className="choose-market">Grab it hurry.</p>
                                <button className="btn shopnow">Shop Now</button>
                            </div>
                            <div className="col-sm-6"></div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="orange-fade">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2 className="popular-product"><span>Popular</span> Products</h2>

                            <div className="products-slide">

                                <Carousels />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <h2 className="popular-product"><span>Featured </span>Categories</h2>
            </div>
            <FeaturedCategory />
            <section className="section-featured">
                <div className="container">
                    <div className="featured-categories">
                        <ul className="categories-list">
                            <li>
                                <div className="f-clist1">
                                    <img src="image/icon1.png" /><p>Home &<br /> Interior</p>
                                </div>
                            </li>
                            <li>
                                <div className="f-clist2">
                                    <img src="image/icon2.png" /><p>Phones &<br /> Wearbles</p>
                                </div></li>
                            <li>
                                <div className="f-clist3">
                                    <img src="image/icon3.png" /><p>Clothes &<br /> Accessories</p>
                                </div>
                            </li>
                            <li><div className="f-clist4"><img src="image/icon4.png" /><p>Sports & <br />Outdoor</p></div></li>
                            <li><div className="f-clist5"><img src="image/icon5.png" /><p>Health & <br />Beauty</p></div></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="orange-fade">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2 className="popular-product"><span>Recommended </span> For You</h2>

                            <div className="products-slide">

                                <Carousels />
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                    <NavLink to="/Product-list">
                        <button class="btn vbutton">View All Products</button>
                        </NavLink>
                    </div>
                </div>
            </section>

           < Maincategory />
            <section className="g-ads">
                <div className="container">
                    <div className="row ">
                        <div className="col-sm-12">
                            <div className="text-center">
                                <img src="image/ad.jpg" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}