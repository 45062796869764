import React, { useEffect, useState } from 'react';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import renderHTML from 'react-render-html';

export default function Shoppingguides() {
   
    const [data, setData] = useState('')
    useEffect(() => {
        fetch('https://admin.pesabee.sdsstaging.co.uk/api/shoppingGuide', { method: 'GET' })
          .then(res => res.json())
          .then((result) => {
            setData(result)
            console.log(data)
          })
          .catch(error => console.log(error))
      }, [])


    return (
        <>
            <Header />
            <section className="about-banner inner-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title  text-center">
                                <h2>{data.title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-overlay">
                <div className="container">
                    <div className="about-content">
                    {renderHTML(`${data.description}`)}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}