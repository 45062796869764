import React, { useEffect, useState } from 'react';
import { Link as NavLink } from 'react-router-dom';
import GoogleTranslate from './Googletranslate';
export default function Header() {
  const [data, setData] = useState([])
  useEffect(() => {
    fetch('https://pesabee.sdsstaging.co.uk/API/api/category_with_subcategory', { method: 'GET', headers: { "Content-Type": "application/json" } })
      .then(res => res.json())
      .then((result) => {
        setData(result.data)
      })
      .catch(error => console.log(error))
  }, [])



  const [searchdata, setSearchdata] = useState('')

  function handle(e) {
    const newdata = e.target.value
    setSearchdata(newdata)
  }
  var a = 0;
  function show_hide() {
    if (a == 1) {
      document.getElementById("menu-area").style.display = "block";
      return a = 0;
    }
    else {
      document.getElementById("menu-area").style.display = "none";
      return a = 1;
    }
  }

  return (
    <>
      <header id="header-area" className="">
        <div id="header-top" className=" d-xl-block">
          <div className="container">
            <div className="row">

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="menu-icon">
                  <button onClick={(e) => show_hide()}>
                    <i class="fa fa-bars" aria-hidden="true"></i>
                  </button>
                </div>
                <div className="header-language-icons">
                  <div className="language">
                    <img src={window.location.origin + '/image/eng.png'} alt="" />
                    <GoogleTranslate />
                    <select id="select" className="lang-select">
                      <option id="english" value="english">GBP</option>
                      <option id="spanish" value="spanish">GBP 1</option>
                      <option id="portuguese" value="portuguese">GBP 2</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="header-middle">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <NavLink to="/" className="logo" title="Pesabee">
                  <img src={window.location.origin + '/image/header-logo.png'} alt="" />
                </NavLink>
              </div>
              <div className="col-lg-7 d-xl-block">
                <div className="row searchFiltercategory" >
                  <form >
                    <div className="group-search" >
                      <input id="table_filter" onChange={(e) => handle(e)} value={searchdata} type="text" className="form-control" placeholder="Search here..." />
                      <div className="group-category" >
                        <button type="button" className="btn dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ><span className="label-icon" >All Categories</span><i className="fa fa-caret-down" aria-hidden="true"></i></button>
                        <div className="dropdown-menu" >
                          <ul className="category_filters" >
                            {
                              data && data.map((dataclear) => (
                                <li>
                                  <NavLink className="dropdown-item" to={"/Product-lists/" + dataclear?.category_name}>{dataclear?.category_name}</NavLink>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="header-search">

                      <NavLink className="dropdown-item" to={"/Product-lists/" + searchdata}>
                        <button type="submit">Search</button>
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-2 col-md-12">

                <div className="fafonts-display">
                  <i className="fa fa-user-o"></i>
                  <span className="fa fa-heart-o position-relative favorite"> </span>
                  <i className="fa fa-balance-scale" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="header-bottom">
          <div className="container">
            <div className="row">

              <div className="col-sm-5"><nav className="navbar navbar-expand-sm">
                <ul className="navbar-nav">

                  <li className="nav-item dropdown">
                    <NavLink className="nav-link dropdown-toggle" to="/" id="navbardrop" data-toggle="dropdown">
                      All Products
                    </NavLink>
                    <div className="dropdown-menu subcategory">
                      <ul className="category_filters" >
                        {
                          data && data.map((dataclear) => (

                            <li className=" cate-name">
                              <NavLink className="dropdown-item cate-link col-lg-3" to={"/Product-lists/" + dataclear?.category_name} >
                                <h6>{dataclear?.category_name}</h6>

                              </NavLink>
                              <ul className="sub-category">
                                {dataclear.sub_category && dataclear.sub_category.map((subcategorylist) => (
                                  <li><NavLink to={"/Product-lists/" + subcategorylist?.sub_category_name}>{subcategorylist.sub_category_name}</NavLink></li>

                                ))}
                              </ul>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </li>
                </ul>
              </nav></div>
              <div className="col-sm-7" id="menu-area">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                  <span className="fa fa-bars"></span>
                </button>
                <nav className="navbar navbar-expand-sm deal-navbar mainmenu collapse navbar-collapse" id="collapsibleNavbar">

                  <ul className="navbar-nav">


                    <li className="nav-item">
                      <NavLink className="nav-link" to="/Deals">Deals</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/Expert-review">Expert Reviews</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/Price-drops">Price Drops</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/Trends">Trends</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/Shopping-guides">Shopping Guides</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/Store">Stores</NavLink>
                    </li>
                  </ul>
                </nav></div>

            </div>

          </div>
        </div>





      </header>
    </>
  )
}