import React, { useEffect, useState } from 'react';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import { Link as NavLink } from 'react-router-dom';

export default function ProductList() {

    const [data, setData] = useState([])
    const [data2, setData2] = useState([])
    const [min, setmin] = useState(0)
    const [max, setmax] = useState(0)
    useEffect(() => {
        fetch('https://pesabee.sdsstaging.co.uk/API/api/product_list', { method: 'GET', headers: { "Content-Type": "application/json" } })
            .then(res => res.json())
            .then((result) => {
                console.log(result)
                setData(result.data)
                setData2(result.data)
            })

            .catch(error => console.log(error))
    }, [])

    const filterminmax = () => {
        let xvaluefil = data2.filter(x => {
            var check = x.mrp.substr(x.mrp.lastIndexOf('\\') + 1).split('-')[1]
            if (parseInt(check) > min && parseInt(check) < max) {
                return x
            }
        })
        setData(xvaluefil)
    }
    return (
        <>
            <Header />


            <section className="section-category">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="col-lg-2 list-area">
                                <ul>
                                    {
                                        data && data.map((dataclear) => (
                                            <li><NavLink to="/">{dataclear?.category_name}</NavLink></li>
                                        ))}
                                </ul>
                            </div>
                            <div className="col-lg-10 dtl-area px-5">
                                <p className="text-left">All Categories  </p>
                                <div className="dtl-top">
                                    <label className="mx-2">Price: </label> 
                                    <input placeholder="min" type="text" className="mx-2" onChange={e => setmin(e.target.value)} />
                                    <input className="mx-2" placeholder="max" type="text" onChange={e => setmax(e.target.value)} />
                                    <button className="filsearch" onClick={e => filterminmax()}>search</button>

                                </div>
                                <ul className="item-list">
                                    {
                                        data && data.map((dataclear) => (
                                            <NavLink to={"/Product-detail/" + dataclear?.id} >
                                                <li className="col-lg-3">
                                                    <div className="item-dtl">
                                                        <img src={dataclear?.Image_URL} alt="product image" />
                                                        <p className="item-name">{dataclear?.product_name}</p>
                                                        <h5>{dataclear?.mrp}</h5>
                                                        <p><span className="sale">SALE</span> US $0.95-4.76</p>
                                                        <p>Free Shipping</p>
                                                        <div className="rating">

                                                            <p><i className="fa fa-star col-orange" aria-hidden="true"></i> 4.8</p>
                                                            <span>894</span>
                                                        </div>
                                                        <NavLink to="/">Amy' Cloth Store</NavLink>
                                                    </div>
                                                </li>
                                            </NavLink>
                                        ))
                                    }
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>



            <Footer />
        </>
    )
}