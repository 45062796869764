import React from 'react';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import { Link as NavLink } from 'react-router-dom';

export default function Blog() {
  return (
    <>
      <Header />
      <section className="about-banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title  text-center">
                <h2>Blogs</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-overlay ">
        <div className="container">
          <div className="row">
            <main className="posts-listing col-lg-8">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="blog_post">
                      <div className="post-thumbnail">
                        <NavLink to="/"><img src="https://cbj.sdsstaging.co.uk/assets/image/blog1.png" alt="..." className="img-fluid" /></NavLink>
                      </div>
                      <div className="post-details">
                        <div className="post-meta d-flex justify-content-between">
                          <div className="blog_date">20 May | 2016</div>
                          <div className="blog_category"><NavLink to="/">Business</NavLink></div>
                        </div><NavLink to="/" >
                          <h3 className="blog_heading">Alberto Savoia Can Teach You About Interior</h3></NavLink>
                        <p className="blog_content text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        <footer className="blog_post-footer d-flex align-items-center"><NavLink to="/" className="author d-flex align-items-center flex-wrap">
                          <div className="blog_avatar">
                            <img src="https://d19m59y37dris4.cloudfront.net/blog/1-2-1/img/avatar-3.jpg" alt="..." className="img-fluid" />
                          </div>
                          <div className="blog_title"><span>John Doe</span></div></NavLink>
                          <div className="blog_date"><i className="fa fa-clock-o" aria-hidden="true"></i> 2 months ago</div>
                          <div className="blog_comments"><i className="fa fa-commenting" aria-hidden="true"></i>12</div>
                        </footer>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="blog_post">
                      <div className="post-thumbnail">
                        <NavLink to="/"><img src="https://cbj.sdsstaging.co.uk/assets/image/blog1.png" alt="..." className="img-fluid" /></NavLink>
                      </div>
                      <div className="post-details">
                        <div className="post-meta d-flex justify-content-between">
                          <div className="blog_date">20 May | 2016</div>
                          <div className="blog_category"><NavLink to="/">Business</NavLink></div>
                        </div><NavLink to="/">
                          <h3 className="blog_heading">Alberto Savoia Can Teach You About Interior</h3></NavLink>
                        <p className="blog_content text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        <footer className="blog_post-footer d-flex align-items-center"><NavLink to="/" className="author d-flex align-items-center flex-wrap">
                          <div className="blog_avatar">
                            <img src="https://d19m59y37dris4.cloudfront.net/blog/1-2-1/img/avatar-3.jpg" alt="..." className="img-fluid" />
                          </div>
                          <div className="blog_title"><span>John Doe</span></div></NavLink>
                          <div className="blog_date"><i className="fa fa-clock-o" aria-hidden="true"></i> 2 months ago</div>
                          <div className="blog_comments"><i className="fa fa-commenting" aria-hidden="true"></i>12</div>
                        </footer>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="blog_post">
                      <div className="post-thumbnail">
                        <NavLink to="/"><img src="https://cbj.sdsstaging.co.uk/assets/image/blog1.png" alt="..." className="img-fluid" /></NavLink>
                      </div>
                      <div className="post-details">
                        <div className="post-meta d-flex justify-content-between">
                          <div className="blog_date">20 May | 2016</div>
                          <div className="blog_category"><NavLink to="/">Business</NavLink></div>
                        </div><NavLink to="/">
                          <h3 className="blog_heading">Alberto Savoia Can Teach You About Interior</h3></NavLink>
                        <p className="blog_content text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        <footer className="blog_post-footer d-flex align-items-center"><NavLink to="/" className="author d-flex align-items-center flex-wrap">
                          <div className="blog_avatar">
                            <img src="https://d19m59y37dris4.cloudfront.net/blog/1-2-1/img/avatar-3.jpg" alt="..." className="img-fluid" />
                          </div>
                          <div className="blog_title"><span>John Doe</span></div></NavLink>
                          <div className="blog_date"><i className="fa fa-clock-o" aria-hidden="true"></i> 2 months ago</div>
                          <div className="blog_comments"><i className="fa fa-commenting" aria-hidden="true"></i>12</div>
                        </footer>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="blog_post">
                      <div className="post-thumbnail">
                        <NavLink to="/"><img src="https://cbj.sdsstaging.co.uk/assets/image/blog1.png" alt="..." className="img-fluid" /></NavLink>
                      </div>
                      <div className="post-details">
                        <div className="post-meta d-flex justify-content-between">
                          <div className="blog_date">20 May | 2016</div>
                          <div className="blog_category"><NavLink to="/">Business</NavLink></div>
                        </div><NavLink to="/">
                          <h3 className="blog_heading">Alberto Savoia Can Teach You About Interior</h3></NavLink>
                        <p className="blog_content text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        <footer className="blog_post-footer d-flex align-items-center"><NavLink to="/" className="author d-flex align-items-center flex-wrap">
                          <div className="blog_avatar">
                            <img src="https://d19m59y37dris4.cloudfront.net/blog/1-2-1/img/avatar-3.jpg" alt="..." className="img-fluid" />
                          </div>
                          <div className="blog_title"><span>John Doe</span></div></NavLink>
                          <div className="blog_date"><i className="fa fa-clock-o" aria-hidden="true"></i> 2 months ago</div>
                          <div className="blog_comments"><i className="fa fa-commenting" aria-hidden="true"></i>12</div>
                        </footer>
                      </div>
                    </div>
                  </div>
                </div>
                <nav aria-label="Page navigation example">
                  <ul className="pagination pagination-template d-flex justify-content-center">
                    <li className="page-item"><NavLink to="/" className="page-link"> <i className="fa fa-angle-left"></i></NavLink></li>
                    <li className="page-item"><NavLink to="/" className="page-link active">1</NavLink></li>
                    <li className="page-item"><NavLink to="/" className="page-link">2</NavLink></li>
                    <li className="page-item"><NavLink to="/" className="page-link">3</NavLink></li>
                    <li className="page-item"><NavLink to="/" className="page-link"> <i className="fa fa-angle-right"></i></NavLink></li>
                  </ul>
                </nav>
              </div>
            </main>
            <aside className="col-lg-4">
              <div className="widget_search">
                <h3 className="widget_heading">Search the blog</h3>
                <form action="#" className="search-form">
                  <div className="form-group">
                    <input type="search" placeholder="What are you looking for?" className="form-control" />
                    <button type="submit" className="search-submit"><i className="fa fa-search"></i></button>
                  </div>
                </form>
              </div>
              <div className="widget_search">
                <h3 className="widget_heading">Latest Posts</h3>
                <div className="blog-posts">
                  <NavLink to="/">
                    <div className="blog_item d-flex align-items-center">
                      <div className="blog_image">
                        <img src="https://cbj.sdsstaging.co.uk/assets/image/blog1.png" alt="..." className="img-fluid" /></div>
                      <div className="post_title"><strong>Alberto Savoia Can Teach You About</strong>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink to="/">
                    <div className="blog_item d-flex align-items-center">
                      <div className="blog_image">
                        <img src="https://cbj.sdsstaging.co.uk/assets/image/blog1.png" alt="..." className="img-fluid" /></div>
                      <div className="post_title"><strong>Alberto Savoia Can Teach You About</strong>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}