import React, { useEffect, useState } from 'react';
import { Link as NavLink } from 'react-router-dom';

export default function Maincategory() {
    const [data, setData] = useState([])
    useEffect(() => {
        fetch('https://pesabee.sdsstaging.co.uk/API/api/category_with_subcategory', { method: 'GET', headers: { "Content-Type": "application/json" } })
            .then(res => res.json())
            .then((result) => {
                setData(result.data)
            })
            .catch(error => console.log(error))
    }, [])
    return (
        <>
            <section className="section-category">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="deals-list">
                                <ul className="categories-link">
                                    {
                                        data && data.map((dataclear) => (
                                            <li>
                                                <NavLink to={"/Product-lists/" + dataclear?.category_name}>
                                                    <div className="f-accesoories"><img src={dataclear?.category_icon} alt="" /></div>
                                                    <div className="daily-deals daily_para"><p>{dataclear?.category_name} </p></div>
                                                </NavLink>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}