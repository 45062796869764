import React, { useEffect, useState } from 'react';
import Header from '../includes/Header';
import Footer from '../includes/Footer';

export default function Contact() {
    const [data, setData] = useState({
        name: "",
        email: "",
        subject: "",
        message: ""
    })
    function submit(e) {
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let datasend = {
            name: data.name,
            email_id: data.email,
            subject: data.subject,
            message: data.message
        }
        var raw = JSON.stringify(datasend);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            // redirect: 'follow'
        };
        fetch("https://pesabee.sdsstaging.co.uk/API/api/contact-us", requestOptions)
            .then(response => response.json())
            .then(function (result) {
console.log(result)
                if (result.success == true) {
                    alert('Your data is successful submit')
                    window.location.reload(false)
                }
                else {
                    alert('Network Error, so try again')
                }
            })
            .catch(error => console.log('error', error))
    }
    function handle(e) {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }
    return (
        <>
            <Header />
            <section className="contact-us inner-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title  text-center">
                                <h2>Contact Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className=" section-overlay">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="contact-form">
                                <form onSubmit={(e) => submit(e)}>
                                    <div className="form-group">
                                        <input onChange={(e) => handle(e)} id="name" value={data.name} type="text" placeholder="Full Name" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <input onChange={(e) => handle(e)} id="email" value={data.email} type="email" placeholder="Email Address" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <input onChange={(e) => handle(e)} id="subject" value={data.subject} type="subject" placeholder="Subject" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <textarea onChange={(e) => handle(e)} id="message" value={data.message} name="message" cols="30" rows="6" placeholder="Message" className="form-control"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="submit-form">Submit Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-3">
                            <div className="single-widget-right">
                                <h2>Address</h2>
                                <div className="widget-body">

                                    <ul className="get-touch">
                                        <li>
                                            <p><i className="fa fa-map-marker"></i> Lorem ipsum doloer sited amet sited amet</p>
                                        </li>
                                        <li> <a href="mailTo:wolfpack@gmail.com" className="cf_email"><i className="fa fa-envelope"></i> wolfpack.com</a></li>
                                        <li> <a href="tel:778895448" className="cf_email"><i className="fa fa-phone"></i> 778895448</a></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="maparea">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29213.038296132225!2d90.39150904197642!3d23.760577791538438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c783c3404f0d%3A0x76ae0d2edabc81df!2sHatir+Jheel!5e0!3m2!1sen!2sbd!4v1517941663187"></iframe>
            </div>
            <Footer />
        </>
    )
}