import React, { useEffect, useState } from 'react';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import { Link as NavLink } from 'react-router-dom';

export default function Deals() {

    const [data, setData] = useState([])
    useEffect(() => {
        // document.getElementById('loader').style.display='block';
        fetch('https://pesabee.sdsstaging.co.uk/API/api/product_deals', { method: 'GET', headers: { "Content-Type": "application/json" } })
            .then(res => res.json())
            .then((result) => {
                setData(result.data)
            })
            .catch(error => console.log(error))
    }, [])
    
    return (
        <>
            <Header />
            <section className="section-category">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                        <h2 className="text-center heading">Deals</h2>
                            <div className="col-lg-12 dtl-area px-5">
                               
                                <ul className="item-list">
                                    {
                                        data.all_products && data.all_products.map((dataclear) => (
                                            <NavLink to={"/Product-detail/" + dataclear?.id} >
                                            <li className="col-lg-3">
                                                <div className="item-dtl">
                                                    <img src={dataclear?.Image_URL} alt="product image" />
                                                    <p className="item-name">{ dataclear?.product_name }</p>
                                                    <h5>{dataclear?.mrp}</h5>
                                                    <p><span className="sale">SALE</span> US $0.95-4.76</p>
                                                    <p>Free Shipping</p>
                                                    <div className="rating">
                                                        <p><i className="fa fa-star col-orange" aria-hidden="true"></i> 4.8</p>
                                                        <span>894</span>
                                                    </div>
                                                    <NavLink to="/">Amy' Cloth Store</NavLink>
                                                </div>
                                            </li>
                                            </NavLink>
                                        ))
                                    }
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>



            <Footer />
        </>
    )
}