import React, { useEffect, useState } from 'react';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import { Link as NavLink ,useParams } from 'react-router-dom';
export default function Storeproduct() {
    const [data, setData] = useState([])
    const params = useParams()
    let store = params.shop_name
    console.log(store)
    useEffect(() => {
        console.log(store)
        fetch('https://pesabee.sdsstaging.co.uk/API/api/product_list', { method: 'GET', headers: { "Content-Type": "application/json" } })
            .then(res => res.json())
            .then((result) => {
                setData(result.data)
                const d2 = [result.filter((e) => e.data.category_name == store)]
                console.log(d2)
            })
            .catch(error => console.log(error))
    }, [])
    return (
        <>
            <Header />
            <section className="section-category">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                        <h2 className="text-center heading">Store Product</h2>
                            <div className="col-lg-12 px-5">
                                <ul className="item-list store-list">
                                    {
                                        data && data.map((datastore) => (
                                            <NavLink to={"/product-detail"} >
                                            <li className="col-lg-3">
                                                <img src={datastore?.shop_image} alt="product image" />
                                                <h5>{datastore.shop_name}</h5>
                                            </li>
                                            </NavLink>
                                        ))
                                    }
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>



            <Footer />
        </>
    )
}