import React, { useCallback, useEffect, useState } from 'react';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import { Link as NavLink, useParams } from 'react-router-dom';

export default function ProductDetail() {
    const params = useParams()
    let id = params.slug_id

    const [data, setData] = useState([])
    
    useEffect(() => {
        // console.log(id, '875445454')
        fetch('https://pesabee.sdsstaging.co.uk/API/api/product_details/' + id, { method: 'GET', headers: { "Content-Type": "application/json" } })
            .then(res => res.json())
            .then((result) => {
                setData(result.data)
                // console.log(result.data)

            })
    }, [])

    return (
        <>
            <Header />
            <section className="section-category">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">

                            <div className="col-lg-1 product-list">

                                <ul>
                                    <li><NavLink to="/"><img src={data.product_detail?.Image_URL} alt="product image" /></NavLink></li>
                                    <li><NavLink to="/"><img src={data.product_detail?.Image_URL} alt="product image" /></NavLink></li>
                                    <li><NavLink to="/"><img src={data.product_detail?.Image_URL} alt="product image" /></NavLink></li>
                                    <li><NavLink to="/"><img src={data.product_detail?.Image_URL} alt="product image" /></NavLink></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 product-img">
                                <img src={data.product_detail?.Image_URL} alt="product image" />
                            </div>
                            <div className="col-lg-8 product-dtl px-5">


                                <h3>{data.product_detail?.product_name}</h3>

                                <ul className="compare">
                                    <li><i className="fa fa-pencil" aria-hidden="true"></i> Rate</li>
                                    <li><i className="fa fa-bell-o" aria-hidden="true"></i> Price alert</li>
                                    <li><i className="fa fa-balance-scale" aria-hidden="true"></i> Compare</li>
                                </ul>
                                <p>{data.product_detail?.product_description}</p>
                                <p>Compare prices from <span className="low-price">{data.product_detail?.product_price}</span> to <span className="high-price">{data.product_detail?.price_end}</span> <i className="fa fa-circle" aria-hidden="true"></i> Rank <span className="rank">50</span> in <span className="brand">Trainers</span></p>

                                <p className="show-btn"><button className="show">Show all <i class="fa fa-angle-down" aria-hidden="true"></i> </button></p>

                            </div>

                        </div>
                    </div>
                    <div className="row similar_product">
                        <div className="col-sm-12">
                            {
                                data.similer_products && data.similer_products.map((datadetails) => (
                                    <ul>
                                        <a href={datadetails.Item_URL} target="_blank" >
                                            {console.log(datadetails.Item_URL)}
                                            <li className="data_details_list">
                                                
                                                <h3>{datadetails?.product_name}</h3>
                                                <div className="row price-dtl">
                                                <div className="col-sm-12">
                                                <p className="table-name">{datadetails?.table_name}</p>
                                                
                                                <p className="product-price">{datadetails?.product_price}</p>
                                                </div>
                                                </div>
                                            </li>
                                        </a>
                                    </ul>
                                ))
                            }
                        </div>
                    </div>

                </div>
            </section>



            <Footer />
        </>
    )
}