import React, { useEffect, useState } from 'react';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import { Link as NavLink } from 'react-router-dom';

export default function Store() {

    const [data, setData] = useState([])
    useEffect(() => {
        fetch('https://pesabee.sdsstaging.co.uk/API/api/stores', { method: 'GET', headers: { "Content-Type": "application/json" } })
            .then(res => res.json())
            .then((result) => {
                setData(result.data)
            })
            .catch(error => console.log(error))
    }, [])
    return (
        <>
            <Header />
            <section className="section-category">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                        <h2 className="text-center heading">Stores</h2>
                            <div className="col-lg-12 px-5">
                                
                                <ul className="item-list store-list">
                                    {
                                        data && data.map((datastore) => (
                                            <NavLink to={"/Store-Product/" + datastore.shop_name} >
                                            <li className="col-lg-3">
                                                <img src={datastore?.shop_image} alt="product image" />
                                                {console.log(datastore.shop_name)}
                                                <h5>{datastore.shop_name}</h5>
                                            </li>
                                            </NavLink>
                                        ))
                                    }
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>



            <Footer />
        </>
    )
}