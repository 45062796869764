import React, { useEffect, useState } from 'react';
import Header from '../includes/Header';
import renderHTML from 'react-render-html';
import Footer from '../includes/Footer';

export default function Terms() {
    const [data, setData] = useState('')
    useEffect(() => {
        fetch('https://admin.pesabee.sdsstaging.co.uk/api/termcondition', { method: 'GET' })
          .then(res => res.json())
          .then((result) => {
            setData(result)
            console.log(data)
          })
          .catch(error => console.log(error))
      }, [])
    return (
        <>
        <Header />
        <section  className="terms-conditions inner-banner">
  <div className="container">
      <div className="row">
          <div className="col-lg-12">
              <div className="section-title  text-center">
                  <h2>{data.title}</h2>
              </div>
          </div>
      </div>
  </div>
</section>
<section className="section-overlay">
  <div className="container">
    <div className="about-content"> 
    {renderHTML(`${data.description}`)}
    
    </div>
  </div>
</section>
        <Footer />
        </>
    )
}